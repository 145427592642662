@import "../../utils/app.scss";

.informations-view-container {
  display: block;
  width: 100%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;

  @media(min-width: 1200px) {
    width: 900px;
    margin: 40px auto;
  }

  .feed {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;

    &:hover {
      background-color: #fff;
      border: 1px solid $bleu;
      margin: -1px;
      a {
        color: $bleu !important;
      }
    }
  }

  .informations-verification-container {
    margin: 0px auto 40px;
    width: 700px;
    padding-top: 20px;

    @media(max-width: $break_mobile) {
      width: 90%;
      margin: 0px auto 40px;
    }
    .informations-verification-titre {
      font-family: $font-family-pompiere;
      text-align: center;
      font-weight: 500;
    }

    .informations-verification-text {
      text-align: center;
      font-weight: 300;
    }
  }
}
