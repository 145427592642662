@import "../../utils/app.scss";

.loginInputContainer {
  min-width: 200px;
  padding: 10px 15px;

  .loginEtape {
    width: 120px;
    margin-left: auto;
    margin-right: auto;

    p {
      color: $gris_tres_fonce;
      font-weight: 200;
      text-align: center;
      margin-top: 10px;

      .bold {
        font-weight: 600;
        color: $bleu;
      }
    }
  }

  .title {
    text-align: center;
    color: $gris_tres_fonce;
    font-weight: 600;
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: $break_mobile) {
      width: 300px;
    }
  }

  .subtitle {
    text-align: center;
    color: $gris_tres_fonce;
    font-weight: 200;

    @media(max-width: $break_mobile) {
      width: 300px;
    }
  }

  .form {
    margin: 10px auto;
    width: 450px;

    .multiple-input {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width: $break_mobile) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .div-input {
        width: 200px;

        @media(max-width: $break_mobile) {
          width: 300px;
        }
      }
    }

    .div-input {
      width: 250px;
      margin: 10px auto;

      @media(max-width: $break_mobile) {
        width: 300px;
      }

      &.long {
        width: 425px;

        @media(max-width: $break_mobile) {
          width: 300px;
        }
      }

      .ant-select {
        width: 100%;
      }

      input {
        border-radius: 5px;
      }

      span {
        border-radius: 5px;
      }

      label {
        font-weight: 600;
        color: $gris_tres_fonce;
        font-size: 12px;

        span {
          font-weight: 200;
        }
      }

      .password-checker {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        margin-top: 10px;

        p {
          color: red;
          margin-bottom: 0px;
          font-size: 12px;

          &.done {
            color: #52c41a;
          }
        }
      }
    }

    @media(max-width: $break_mobile) {
      width: 300px;
    }

    .errorDiv {
      background: #fff;
      color: $gris_tres_fonce;
      padding: 5px 20px;
    }

    .formatError {
      text-align: center;
      margin-top: 10px;
      color: red;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .submit {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      button {
        padding: 5px 15px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        background: $bleu;
        border: 1px solid $bleu;
        border-radius: 5px;

        &:disabled {
          background: $gris_clair;
          cursor: auto;
          border: 1px solid $gris_clair;
        }
      }

      .button {
        padding: 5px 15px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        background: $bleu;
        border: 1px solid $bleu;
        border-radius: 5px;

        &.disabled {
          background: $gris_clair;
          cursor: auto;
          border: 1px solid $gris_clair;
        }
      }

      a {
        padding: 5px 15px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        background: $bleu;
        border: 1px solid $bleu;
        border-radius: 5px;
        text-decoration: none;
        color: #fff;
      }

      .skip-part {
        padding: 5px 15px;
        text-align: center;
        cursor: pointer;
        color: $bleu;
        width: 100%;
        background: #fff;
        border: 1px solid $bleu;
        border-radius: 5px;
      }
    }
  }
}

.errorDiv {
  background: #fff;
  padding: 5px 20px;

  p {
    color: #e25950;
    text-align: center;
    margin: 15px 0 10px;
    line-height: 20px;
    padding-left: 20px;
  }
}
