@import "../../utils/app.scss";

.help-bottom-part {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: $break_tablet) {
    text-align: justify;
  }

  p {
    font-size: 16px;
    font-weight: 200;
  }
}

.help-top-part {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: $break_tablet) {
    flex-direction: column;
  }

  .item {
    display: block;
    width: 31%;
    margin: 20px 1%;

    @media(max-width: $break_tablet) {
      width: 100%;
      margin: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    img {
      max-width: 100%;
      height: 120px;
    }

    .titre {
      font-weight: 600;
      font-size: 15px;

      @media(max-width: $break_tablet) {
        text-align: center;
      }
    }
  }
}
