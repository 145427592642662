@import "../../utils/app.scss";

.collecte-reussi {
  width: 100%;
  margin: 40px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .collecte-reussi-titre {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 40px;

    .collecte-reussi-titre-number {
      color: $bleu;
      font-family: $font-family-parisienne;
      font-size: 6em;
      margin-bottom: 0px;
      margin-right: 10px;
      font-weight: 600;
    }

    .collecte-reussi-titre-text {
      color: $regular;
      margin-bottom: 0px;
      margin-left: 10px;
      font-size: 2em;
      line-height: 1.3em;
    }
  }

  .collecte-reussi-round {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    margin: auto;
    top: 30px;
    background-color: $bleu;
    z-index: 9;

    @media(max-width: $break_tablet) {
      display: none;
    }
  }

  .collecte-reussi-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    position: relative;
    min-height: 400px;

    &:last-child {
      padding-bottom: 40px;
    }

    .collecte-reussi-timeline {
      width: 2px;
      background-color: $bleu;
      position: absolute;
      margin: auto;
      height: 100%;

      &.last-child {
        height: 50%;
        bottom: 50%;
        z-index: 10;
      }

      &.last-last-child {
        height: 50%;
        top: 50%;
        z-index: 10;
        background-color: #fff;
        width: 40px;
      }

      @media(max-width: $break_tablet) {
        display: none;
      }
    }

    @media(max-width: $break_tablet) {
      flex-direction: column-reverse;
    }

    &:nth-child(2n) {
      @media(max-width: $break_tablet) {
        flex-direction: column;
      }
    }

    .collecte-reussi-body-separator {
      width: 100px;
      height: 1px;
      background-color: $bleu;
      padding: 0px 20px;
      margin: 40px auto 0px;

      @media(min-width: $break_tablet) {
        display: none;
      }
    }

    .collecte-reussi-body-number {
      color: $bleu;
      font-family: $font-family-parisienne;
      font-size: 50px;
      margin-bottom: 0px;
      font-weight: 600;
      text-align: center;
      width: 200px;
      z-index: 11;
      background-color: #fff;

      &.desktop {
        display: block;
        @media(max-width: $break_tablet) {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media(max-width: $break_tablet) {
          display: block;
        }
      }
    }

    .collecte-reussi-body-paragraph {
      text-align: center;
      width: 300px;

      .collecte-reussi-body-paragraph-titre {
        font-weight: 700;
        margin-bottom: 0px;
        font-size: 3em;
      }
      .collecte-reussi-body-paragraph-text {
        margin-bottom: 0px;
        font-size: 1.2em;
      }
    }

    .collecte-reussi-body-image-1 {
      width: 300px;
      height: 175px;
      background-image: url("https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/01_completez.gif");
      background-size: cover;
      background-position: 50% 50%;
    }

    .collecte-reussi-body-image {
      height: auto;
      width: 300px;

      @media(max-width: $break_tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .collecte-reussi-partage-delimiter {
    border-top: 0.5px solid $bleu;
    margin: 30px auto;
    width: 60%;
  }

  .collecte-reussi-partage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;


    @media(max-width: $break_tablet) {
      flex-direction: column;
    }

    .collecte-reussi-partage-paragraph {
      text-align: center;
      width: 325px;
      margin-right: 25px;

      @media(max-width: $break_tablet) {
        margin-right: 0px;
        margin-bottom: 20px;
      }

      .collecte-reussi-partage-paragraph-titre {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .collecte-reussi-partage-paragraph-text {
        margin-bottom: 0px;
      }
    }

    .collecte-reussi-partage-text-a-copier {
      background-color: #fff;
      border: 1px solid $bleu;
      border-radius: 5px;
      padding: 10px;
      width: 325px;
      margin-left: 25px;
      text-align: left;

      @media(max-width: $break_tablet) {
        margin-left: 0px;
      }
    }
  }
}
