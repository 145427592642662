@import "../../utils/app.scss";

.search-container {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  .search {
    width: 300px;
    background: $bleu_clair;
    border: $bleu_clair;
    border-radius: 5px;

    input {
      background: transparent !important;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }

    span {
      color: #fff;
    }
  }
}

.products-block {
  margin: 20px 10px 40px;

  .products-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

    .product-category {
      padding: 5px 10px;
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        font-weight: 600;
        border: 1px solid $bleu;
        --fa-primary-color: #2b8bbc;
        --fa-secondary-color: #2b8bbc;
      }

      &.active {
        font-weight: 600;
        border: 1px solid $bleu;
        --fa-primary-color: #2b8bbc;
        --fa-secondary-color: #2b8bbc;
      }
    }
  }

  .products-sous-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .product-sous-category {
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        border: 1px solid $bleu;
        padding: 5px 10px;
        color: $bleu;
        font-weight: 600;
      }

      &.active {
        border-radius: 5px;
        border: 1px solid $bleu;
        padding: 5px 10px;
        color: $bleu;
        font-weight: 600;
      }
    }
  }

  .product-display-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .product-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 18%;
      margin: 10px 1%;
      cursor: auto;

      &.alt {
        border: 0px;
        cursor: auto !important;

        &:hover {
          font-weight: 400 !important;
          border: none !important;
        }
      }

      &.ghost {
        background-color: $gris_clair;
        border-radius: 5px;
        border: none;
        background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;
      }

      @media(max-width: $break_tablet) {
        width: 31%;
      }

      @media(max-width: $break_mobile) {
        width: 48%;
      }

      &.active {
        border: 2px solid $bleu;
      }

      .image {
        height: 170px;
        width: 100%;
        text-align: center;

        img {
          max-width: 100%;
        }
      }
      .titre {
        text-align: center;
        padding: 5px;
        margin-bottom: 0px;
      }

      .price {
        text-align: center;
        font-weight: 600;
        color: #000;
      }
    }
  }
}
