@import "../../utils/app.scss";

.collectes-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .feed {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;

    &:hover {
      background-color: #fff;
      border: 1px solid $bleu;
      margin: -1px;
      a {
        color: $bleu !important;
      }
    }
  }

  .title {
    font-family: $font-family-pompiere;
    font-size: 30px;
    margin-top: 40px;
    text-align: center;
  }
}
