@import "../../utils/app.scss";

.nouvelle-collecte-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .nouvelle-collecte-tracker {
    width: 100px;
    margin-top: 40px;
  }
}
