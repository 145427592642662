@import "../../utils/app.scss";

.help-container {
  width: 100%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;

  @media(min-width: 1200px) {
    width: 900px;
    margin: 40px auto;
  }

  video {
    width: 900px;
    height: auto;

    @media(max-width: $break_tablet) {
      width: 500px;
    }

    @media(max-width: $break_mobile) {
      width: 300px;
    }
  }

  .help-titre {
    text-align: center;
    font-family: $font-family-pompiere;
    margin: 30px auto 40px;
    font-size: 3em;

    @media(max-width: $break_tablet) {
      font-size: 2em;
    }
  }

  .help-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .link-block {
      width: 31%;
      margin: 1%;

      @media(max-width: $break_tablet) {
        width: 98%;
      }

      .block {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 50%;
        height: 200px;
      }
    }
  }

  .feed {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;

    &:hover {
      background-color: #fff;
      border: 1px solid $bleu;
      margin: -1px;

      a {
        color: $bleu !important;
      }
    }
  }
}

.retour-aide {
  background-color: #fff;
  cursor: pointer;
  padding: 10px 25px;
  align-self: flex-end;
  border-radius: 5px;
  font-size: 16px;
  width: 150px;
  margin: 20px;
  text-align: center;
  border: 1px solid $bleu;

  a {
    color: $bleu;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  &:hover {
    background-color: $bleu;

    a {
      color: #fff;
      
      svg {
        margin-right: 5px;
      }
    }
  }
}
