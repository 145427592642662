@import "../../utils/app.scss";

.collecte-view-container {
  display: block;
  width: 100%;

  @media(min-width: 1200px) {
    width: 900px;
    margin: 0px auto;
  }

  .collecte-view-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .collecte-view-button-view-collecte {
      background-color: $bleu;
      padding: 10px 15px;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 15px;

      &:hover {
        background-color: #fff;
        border: 1px solid $bleu;
        margin: -1px 15px -1px -1px;

        a {
          color: $bleu !important;
        }
      }
    }

    .collecte-view-button-annuler {
      background-color: red;
      padding: 10px 15px;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        border: 1px solid red;
        margin: -1px;
        color: red !important;
      }

      @media(max-width: $break_tablet) {
        margin-right: 15px;
        &:hover {
          margin: -1px 15px -1px -1px;
        }
      }
    }
  }

  .collecte-view-titre-container {
    text-align: center;
    align-self: center;

    .collecte-view-titre {
      font-weight: 700;
      margin-bottom: 0px;
    }

    .collecte-view-sous-titre {
      color: $gris_moyen_clair;
      font-style: italic;
    }

    .collecte-view-partage {
      border: 1px solid $gris_tres_clair;
      border-radius: 5px;
      padding: 20px 30px;

      .lien-text {
        margin-bottom: 0px;
        color: $bleu;
        font-weight: 300;
        text-align: center;
      }

      .lien {
        background-color: $gris_tres_clair;
        border-radius: 5px;
        padding: 10px 15px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        cursor: pointer;
        width: 250px;
        margin-right: auto;
        margin-left: auto;

        &:hover {
          border: 1px solid $bleu;
          margin: -1px auto; 
        }

        input {
          border: none;
          outline: none;
          background-color: $gris_tres_clair;
          width: 200px;
          cursor: inherit;
          font-size: 12px;
        }
      }
    }
  }
}
